import Swiper from 'swiper';
import {Navigation, Pagination, EffectCreative, Autoplay } from 'swiper/modules';
Swiper.use([Pagination, Navigation, EffectCreative, Autoplay]);

const servicesSlider  = new Swiper('.services-slider',{
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  spaceBetween: 30,
  navigation:{
    nextEl: ".services-arrow-next",
    prevEl: ".services-arrow-prev",
  },
  breakpoints: {
    280: {
      slidesPerView: 1.5,
    },
    600: {
      slidesPerView: 2.5,
    },
    1000: {
      slidesPerView: 3.5,
    },
    1400: {
      slidesPerView: 5,
    }
  }
})
