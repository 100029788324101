import Swiper from 'swiper';
import {Scrollbar } from 'swiper/modules';
Swiper.use([Scrollbar]);

const servicesSlider = new Swiper('.flip-slider', {
  direction: 'horizontal',
  slidesPerView: 1,
  autoHeight: false,
  spaceBetween: 20,
  scrollbar: {
    el: ".swiper-scrollbar",
    draggable: true,
  },
  breakpoints: {
    280: {
      slidesPerView: 1.5,
    },
    650: {
      slidesPerView: 2.5,
    },
    950: {
      slidesPerView: 3.5,
    },
    1400: {
      slidesPerView: 5,
    }
  }
});
